import React, { useState } from 'react';
import axios from 'axios';
import './Register.css'; // Ensure the CSS file is correctly imported
import { useNavigate } from 'react-router-dom';

function Register() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [client_id, setClientID] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Les mots de passe ne correspondent pas.');
      return;
    }
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    if (!usernameRegex.test(username)) {
      setMessage('Le nom d\'utilisateur ne peut contenir que des lettres et des chiffres, sans espaces ni caractères spéciaux.');
      return;
    }
    if (!usernameRegex.test(client_id)) {
      setMessage('Le nom de la page ne peut contenir que des lettres et des chiffres, sans espaces ni caractères spéciaux.');
      return;
    }
    try {
      const response = await axios.post(
        `/api/register`,
        {
          email,
          password,
          username: username.toLowerCase(),
          client_id: client_id.toLowerCase(),
        }
      );

      setMessage(response.data.message);
      setTimeout(() => navigate('/login'), 1000);
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value.toLowerCase();
    const usernameRegex = /^[a-zA-Z0-9]*$/;
    if (usernameRegex.test(value)) {
      setUsername(value);
    }
  };

  const handleClientIDChange = (e) => {
    const value = e.target.value.toLowerCase();
    const clientIDRegex = /^[a-zA-Z0-9]*$/;
    if (clientIDRegex.test(value)) {
      setClientID(value);
    }
  };

  return (
    <div className="register-container">
      <h2>S'enregistrer</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Email *
          <div className="input-wrapper">
            <input
              className="input-field"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="button" className="info-button" title="Entrez votre email">i
              <span className="tooltip">Votre adresse email sera utilisée pour valider votre compte.</span>
            </button>
          </div>
        </label>
        <label>
          Prénom * 
          <div className="input-wrapper">
            <input
              className="input-field"
              type="text"
              value={username}
              onChange={handleUsernameChange}
              required
            />
            <button type="button" className="info-button" title="Entrez votre prénom">i
              <span className="tooltip">Entrez votre prénom</span>
            </button>
          </div>
        </label>
        <label>
          Nom de votre page *
          <div className="input-wrapper">
            <input
              className="input-field"
              type="text"
              value={client_id}
              onChange={handleClientIDChange}
              required
            />
            <button type="button" className="info-button" title="Entrez le nom de votre page">i
              <span className="tooltip">Votre nom de page devrait correspondre au nom de votre business.</span>
            </button>
          </div>
        </label>
        <label>
          Mot de passe *
          <div className="input-wrapper">
            <input
              className="input-field"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="button" className="info-button" title="Entrez votre mot de passe">i
              <span className="tooltip">Entrez votre mot de passe</span>
            </button>
            <button type="button" className="toggle-password" onClick={toggleShowPassword}>
              {showPassword ? "🙈" : "👁️"}
            </button>
          </div>
        </label>
        <label>
          Confirmer votre mot de passe *
          <div className="input-wrapper">
            <input
              className="input-field"
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button type="button" className="info-button" title="Confirmez votre mot de passe">i
              <span className="tooltip">Confirmez votre mot de passe</span>
            </button>
            <button type="button" className="toggle-password" onClick={toggleShowPassword}>
              {showPassword ? "🙈" : "👁️"}
            </button>
          </div>
        </label>
        <button className="login-button" type="submit">S'enregistrer</button>
      </form>
      {message && <p className="message">{message}</p>}
      <p>Vous avez déjà un compte ? <a href="/login">Connectez-vous ici</a></p>
    </div>
  );
}

export default Register;
