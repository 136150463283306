// FAQ.js
import React from 'react';
import './FAQ.css'; // Make sure to create an FAQ.css file in the appropriate directory

function FAQ() {
  return (
    <div className="faq-container">
      <h1>Questions & Réponses</h1>
      <div className="faq">
        <h2>Quel est le périmètre d'action de Carutile ?</h2>
        <p>L'ensemble de l'Ile-de-France.</p>

        <h2>Quel est le tarif du déplacement ?</h2>
        <p>Le déplacement est inclus dans la prestation de service (gratuit).</p>

        <h2>Combien de temps dure la prestation ?</h2>
        <p>Cela dépend de la formule choisie, la durée estimée est indiquée sur chaque formule au moment de la prise de rendez-vous.</p>

        <h2>Puis-je utiliser mon véhicule directement après la prestation ?</h2>
        <p>Oui, les sièges ne seront pas mouillés. Vous pourrez conduire sans problème.</p>
        
        <h2>Quels sont les lieux d'intervention possibles ?</h2>
        <p>Nous intervenons à domicile, sur le lieu de travail ou sur une station de lavage.</p>

        <h2>Prenez vous en charge les véhicules de professionnels ? (Vendeurs de voitures, Entreprises etc.)</h2>
        <p>Oui nous travaillons également avec les professionels, n'hésitez pas à nous contacter pour vous renseigner ou pour obtenir un devis.</p>
      </div>
    </div>
  );
}

export default FAQ;
