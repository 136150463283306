import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ClientServiceForm.css';

function ClientServiceForm() {
  const [services, setServices] = useState([{
    category: '',
    subcategory: '',
    name: '',
    description: '',
    price: '',
    currency: 'EUR',
    duration: '',
    unit: 'minute'
  }]);

  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    console.log('ClientServiceForm mounted')
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found! Ensure you are logged in.');
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`
    };

    axios.get('/api/dashboardservices', { headers })
      .then(response => {
        const data = response.data;
        if (Array.isArray(data)) {
          if (data.every(service => service.uuid)) {
            setServices(data);
          } else {
            console.error('One or more services are missing UUIDs:', data);
          }
        } else {
          console.error('Data fetched is not an array:', data);
          setServices([]);
        }
      })
      .catch(error => {
        console.error('Error fetching services:', error);
        setServices([]);
      });

  }, []);

  const duplicateService = (index, e) => {
    e.preventDefault();
    console.log("Duplicating service at index:", index);

    if (index >= 0 && index < services.length) {
      const { uuid, ...serviceWithoutUuid } = services[index];
      const newService = {
        ...serviceWithoutUuid,
        isNew: true
      };

      setServices(services => {
        const newServices = [...services];
        newServices.splice(index + 1, 0, newService);
        return newServices;
      });
    }
  };

  const handleServiceChange = (index, e) => {
    console.log("handlingServiceChange");
    let { name, value } = e.target;

    if (name === "duration") {
      value = value === '' ? '' : parseInt(value, 10) || 0;
    }

    if (name === "price") {
      value = value === '' ? '' : parseFloat(value) || 0.0;
    }

    const updatedServices = services.map((service, i) => {
      if (i === index) {
        return { ...service, [name]: value };
      }
      return service;
    });

    setServices(updatedServices);
  };

  const addService = () => {
    setServices([...services, {
      id: null,
      category: '',
      subcategory: '',
      name: '',
      description: '',
      price: '',
      currency: 'EUR',
      duration: '',
      unit: 'minute'
    }]);
  };

  const removeService = (index, e) => {
    console.log("removingService");
    e.preventDefault();
    const service = services[index];
    if (service && service.uuid) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };

      axios.delete(`/api/services/${service.uuid}`, { headers })
        .then(response => {
          const updatedServices = services.filter((_, i) => i !== index);
          setServices(updatedServices);
        })
        .catch(error => {
          console.error('Error deleting service:', error);
          alert('Failed to delete the service');
        });
    } else {
      console.error('Attempted to delete a service without a UUID');
      const updatedServices = services.filter((_, i) => i !== index);
      setServices(updatedServices);
    }
  };

  const handleSubmit = (e) => {
    console.log("handlingSubmit");
    e.preventDefault();
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    let errors = [];
    services.forEach((service, index) => {
      if (!service.category) errors.push({ index, field: 'category', message: 'Merci de renseigner la catégorie' });
      if (!service.name) errors.push({ index, field: 'name', message: 'Merci de renseigner le service' });
      if (!service.price) errors.push({ index, field: 'price', message: 'Merci de renseigner le prix' });
      if (!service.duration) errors.push({ index, field: 'duration', message: 'Merci de renseigner la durée' });
    });

    if (errors.length > 0) {
      setValidationErrors(errors);
      setErrorMessage("Merci de renseigner tous les champs obligatoires");
      return;
    } else {
      setValidationErrors([]);
    }

    services.forEach(service => {
      const method = service.uuid ? 'put' : 'post';
      const url = service.uuid ? `/api/services/${service.uuid}` : '/api/services';

      axios[method](url, service, { headers })
        .then(response => {
          const updatedService = response.data;
          setMessage('Vos modifications ont été enregistrées');
          if (method === 'post') {
            setServices(prevServices => prevServices.map(s => s.uuid ? s : updatedService));
          } else {
            setServices(prevServices => prevServices.map(s => s.uuid === updatedService.uuid ? updatedService : s));
          }
        })
        .catch(error => {
          console.error(`Error ${method === 'put' ? 'updating' : 'saving'} service:`, error);
          alert(`Failed to ${method === 'put' ? 'update' : 'save'} the service`);
          setErrorMessage("Erreur lors de l'enregistrement, veuillez contacter le support");
        });
    });
  };

  const getValidationError = (index, field) => {
    const error = validationErrors.find(error => error.index === index && error.field === field);
    return error ? error.message : '';
  };

  return (
    <div className="client-service-form">
      <h2>Définissez vos services</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <thead>
            <tr>
              <th>Catégorie *</th>
              <th>Sous-Catégorie</th>
              <th>Service *</th>
              <th>Description</th>
              <th>Prix *</th>
              <th>Devise</th>
              <th>Durée *</th>
              <th>Unité</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service, index) => (
              <tr key={service.id || index}>
                <td>
                  <input
                    type="text"
                    name="category"
                    value={service.category}
                    onChange={(e) => handleServiceChange(index, e)}
                    className={getValidationError(index, 'category') ? 'error' : ''}
                  />
                  {getValidationError(index, 'category') && <span className="error-message">{getValidationError(index, 'category')}</span>}
                </td>
                <td>
                  <input
                    type="text"
                    name="subcategory"
                    value={service.subcategory}
                    onChange={(e) => handleServiceChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={service.name}
                    onChange={(e) => handleServiceChange(index, e)}
                    className={getValidationError(index, 'name') ? 'error' : ''}
                  />
                  {getValidationError(index, 'name') && <span className="error-message">{getValidationError(index, 'name')}</span>}
                </td>
                <td>
                  <input
                    type="text"
                    name="description"
                    value={service.description}
                    onChange={(e) => handleServiceChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="price"
                    value={service.price}
                    onChange={(e) => handleServiceChange(index, e)}
                    className={getValidationError(index, 'price') ? 'error' : ''}
                  />
                  {getValidationError(index, 'price') && <span className="error-message">{getValidationError(index, 'price')}</span>}
                </td>
                <td>
                  <select
                    name="currency"
                    value={service.currency}
                    onChange={(e) => handleServiceChange(index, e)}
                  >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    name="duration"
                    value={service.duration}
                    onChange={(e) => handleServiceChange(index, e)}
                    className={getValidationError(index, 'duration') ? 'error' : ''}
                  />
                  {getValidationError(index, 'duration') && <span className="error-message">{getValidationError(index, 'duration')}</span>}
                </td>
                <td>
                  <select
                    name="unit"
                    value={service.unit}
                    onChange={(e) => handleServiceChange(index, e)}
                  >
                    <option value="minute">Minute</option>
                  </select>
                </td>
                <td>
                  <button type="button" onClick={(e) => removeService(index, e)} style={{ background: "#D9534F", color: "white" }}>
                    Supprimer
                  </button>
                  <button type="button" onClick={(e) => duplicateService(index, e)} style={{ background: '#7091E6', color: "white", marginTop: '10px' }}>
                    Dupliquer
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" onClick={addService} style={{ marginTop: '10px' }}>
          +
        </button>
        <button type="submit" style={{ marginTop: '10px', marginLeft: '10px' }}>
          Valider et Publier
        </button>
      </form>
      {message && <div className="success-message">{message}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default ClientServiceForm;
