import React from 'react';
import './TermsOfUse.css'

function TermsOfUse() {
  return (
    <div className='TermsOfUse'>
      <h1>Terms of Use</h1>
        <h2>Introduction</h2>
        <p>
        Welcome to Carutile. By accessing our app, you agree to these Terms of Use, our Privacy Policy, and any other terms and conditions that may apply. 
        Carutile reserves the right to modify these terms at any time without prior notice. Your continued use of the app constitutes agreement to our revisions of these terms.
        </p>
        <h2>Use License</h2>
        
        <p>
        Carutile grants you a limited license to access and use the app for personal, non-commercial purposes.
        This license does not include any resale or commercial use of the app or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of the app or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools.
        </p>
        <h2>Disclaimer</h2>
        <p>
        The materials on Carutile's app are provided on an 'as is' basis. Carutile makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
        </p>

        <h2>Limitations</h2>
        <p>
        In no event shall Carutile or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the app.
        </p>
        <h2>Accuracy of Materials</h2>
        <p>
        The materials appearing on Carutile's app could include technical, typographical, or photographic errors. Carutile does not warrant that any of the materials on its app are accurate, complete, or current. Carutile may make changes to the materials contained on its app at any time without notice.
        </p>

        <h2>Links</h2>
        <p>
        Carutile has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Carutile of the site. Use of any such linked website is at the user's own risk.
        </p>
        <h2>Modifications</h2>
        <p>
        Carutile may revise these Terms of Use for its app at any time without notice. 
        By using this app, you are agreeing to be bound by the then-current version of these Terms of Use.
        </p>
    </div>
  );
}

export default TermsOfUse;
