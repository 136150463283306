import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScroll } from './ScrollManager';

const RouteHandler = ({ children }) => {
  const { scrollToRef, vehicleRef } = useScroll();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/prise-rdv') {
      scrollToRef(vehicleRef);  // Use ScrollProvider's method and refs
    }
  }, [location, scrollToRef, vehicleRef]);

  return <div>{children}</div>;  // No ref here directly, refs are managed by ScrollProvider
};

export default RouteHandler;
