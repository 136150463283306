// Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="App-footer">
      <p>&copy; <script>document.write(new Date().getFullYear())</script> Carutile. Tous les droits sont réservés.</p>
      <div className="footer-links">
        <a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a>
      </div>
      <div className='footer-links'>
        <p><a href="mailto:contact@caru</div>tile.com">contact@carutile.com</a></p>
      </div>
    </footer>

  );
}

export default Footer;
