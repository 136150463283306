import React from 'react';
import './Confirmation.css';
import { useLocation } from 'react-router-dom';

function Confirmation() {
  const location = useLocation();
  const { details, error } = location.state || {};

  const formatTimeSlot = (start_time) => {
    const date = new Date(start_time);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
    const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);
    return formattedDate.replace(':00', 'h').replace(':', 'h');
  }

  return (
    <div className="confirmation-container">
      {error ? (
        <p>{error}</p>
      ) : (
        <>
          <div className="checkmark"></div>
          <h1>Votre Rendez-vous est confirmé ! ✅</h1>
          <p>Nom : {details.name}</p>
          <p>Téléphone : {details.phone_number}</p>
          {details.email && <p>Email : {details.email}</p>}
          <p>Adresse : {details.address}</p>
          <p>Créneau : {formatTimeSlot(details.start_time)}</p>
        </>
      )}
    </div>
  );
}

export default Confirmation;
