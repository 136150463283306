import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const { setUsername } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/login`, { email, password });
      localStorage.setItem('token', response.data.token); // Save token

      setUsername(response.data.username);
      setMessage('Vous vous êtes connectés avec succès');
      setTimeout(() => {
        navigate('/');
        window.location.reload();
      }, 1000);
      
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <h2>Se connecter</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Email *
          <div className="input-wrapper">
            <input
              className="input-field"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </label>
        <label>
          Mot de passe *
          <div className="input-wrapper">
            <input
              className="input-field"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="button" className="toggle-password" onClick={toggleShowPassword}>
              {showPassword ? "🙈" : "👁️"}
            </button>
          </div>
        </label>
        <button className="login-button" type="submit">Se connecter</button>
      </form>
      {message && <p className="message">{message}</p>}
      <p>Vous n'avez pas encore un compte ? <a href="/register">Inscrivez-vous ici</a></p>
    </div>
  );
}

export default Login;
