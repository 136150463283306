import { React, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import Login from './components/users/Login';
import Logout from './components/users/Logout';
import Register from './components/users/Register';
import { useUser, UserProvider } from './components/users/UserContext';
import Schedule from './components/dashboard/Dashboard';
import Setup from './components/setup/Setup';
import ClientService from './components/clients/ClientService';
import HomePage from './components/users/Homepage';

function App() {
  const { username } = useUser();

  return (
    <UserProvider>
      <Router>
        <AppWrapper username={username} />
      </Router>
    </UserProvider>
  );
}

function AppWrapper() {
  const { username } = useUser();
  const location = useLocation();
  const firstSegment = location.pathname.split('/')[1];
  
  const headerDisplayRoutes = ['', 'register', 'login', 'dashboard', 'setup'];
  const showHeader = headerDisplayRoutes.includes(firstSegment);

  useEffect(() => {
    // This ensures that the AppWrapper will rerender when username changes
  }, [username]);

  return (
    <>
      {showHeader && (
        <nav className="App-nav">
          <h1>Carutile.</h1>
          <div className="nav-links">
            <Link to="/">Accueil</Link>
            {!username && (
              <>
                <Link to="/login">Se connecter</Link>
                <Link to="/register">S'enregistrer</Link>
              </>
            )}
            {username && (
              <>
                <Link key="setup-link" to="/setup">Configuration</Link>
                <Link key="dashboard-link" to="/dashboard">Calendrier</Link>
                <Link key="logout-link" to="/logout">Se déconnecter</Link>
              </>
            )}
          </div>
        </nav>
      )}
      <div className="App-content">
        <Routes>
          <Route path="" element={username ? <HomePage /> : <Navigate to="/register" />} />
          <Route path="/login" element={username ? <HomePage /> : <Login />} />
          <Route path="/register" element={username ? <HomePage /> : <Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={username ? <Schedule /> : <Navigate to="/login" />} />
          <Route path="/setup" element={username ? <Setup /> : <Navigate to="/login" />} />
          <Route path="/:clientId/*" element={<ClientService />} /> {/* Dynamic route */}
        </Routes>
      </div>
    </>
  );
}

export default App;
