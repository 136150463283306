import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './Analytics.css';  // Make sure to create this CSS file

const Analytics = () => {
    const [events, setEvents] = useState([]);
    const [dailyData, setDailyData] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);

    const fetchAppointments = useCallback(async () => {
        try {
            const response = await axios.get('/api/client/appointments', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            const appointments = response.data
                .filter(appt => appt.DeletedAt === null)
                .map(appt => ({
                    id: appt.ID,
                    uuid: appt.uuid,
                    title: `${appt.name} - ${appt.email}`,
                    start: new Date(appt.calendar_entry_start_time),
                    end: new Date(appt.calendar_entry_end_time),
                    isBooked: true,
                    details: appt,
                    basket: appt.Basket
                }));
            setEvents(appointments);
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    }, []);

    const calculateAnalytics = useCallback(() => {
        const daily = {};
        const weekly = {};
        const monthly = {};

        events.forEach(event => {
            const date = event.start.toISOString().split('T')[0];
            const week = `${event.start.getFullYear()}-W${Math.ceil((event.start.getDate() + (new Date(event.start.getFullYear(), event.start.getMonth(), 1).getDay())) / 7)}`;
            const month = `${event.start.getFullYear()}-${event.start.getMonth() + 1}`;

            if (!daily[date]) daily[date] = { count: 0, revenue: 0 };
            if (!weekly[week]) weekly[week] = { count: 0, revenue: 0 };
            if (!monthly[month]) monthly[month] = { count: 0, revenue: 0 };

            daily[date].count += 1;
            daily[date].revenue += event.basket.reduce((sum, item) => sum + parseFloat(item.price.replace(/[^\d.-]/g, '')) || 0, 0);

            weekly[week].count += 1;
            weekly[week].revenue += event.basket.reduce((sum, item) => sum + parseFloat(item.price.replace(/[^\d.-]/g, '')) || 0, 0);

            monthly[month].count += 1;
            monthly[month].revenue += event.basket.reduce((sum, item) => sum + parseFloat(item.price.replace(/[^\d.-]/g, '')) || 0, 0);
        });

        setDailyData(Object.entries(daily).map(([date, data]) => ({ date, ...data })));
        setWeeklyData(Object.entries(weekly).map(([week, data]) => ({ week, ...data })));
        setMonthlyData(Object.entries(monthly).map(([month, data]) => ({ month, ...data })));
    }, [events]);

    useEffect(() => {
        fetchAppointments();
    }, [fetchAppointments]);

    useEffect(() => {
        if (events.length > 0) {
            calculateAnalytics();
        }
    }, [events, calculateAnalytics]);
    return (
        <div className="analytics-container">
            <div className="analytics-grid">
                {dailyData.map(data => (
                    <div key={data.date} className="analytics-card">
                        <h3>{data.date}</h3>
                        <p>Rendez-vous: <span className="number">{data.count}</span></p>
                        <p>Revenus: <span className="number">${data.revenue}</span></p>
                    </div>
                ))}
               {weeklyData.map(data => (
                    <div key={data.week} className="analytics-card">
                        <h3>{data.week}</h3>
                        <p>Rendez-vous: <span className="number">{data.count}</span></p>
                        <p>Revenus: <span className="number">${data.revenue}</span></p>
                    </div>
                ))}
                {monthlyData.map(data => (
                    <div key={data.month} className="analytics-card">
                        <h3>{data.month}</h3>
                        <p>Rendez-vous: <span className="number">{data.count}</span></p>
                        <p>Revenus: <span className="number">${data.revenue}</span></p>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Analytics;
