import React, { useState } from 'react';
import ClientHours from './ClientHours';
import '../dashboard/Dashboard.css';
import ClientServiceForm from './ClientServiceForm';

function Dashboard() {
  const [viewMode, setViewMode] = useState('Services'); // Default to 'Schedule'

  return (
    <div className="dashboard-container">
      <div className="view-switch">
        <button onClick={() => setViewMode('Services')} className={viewMode === 'Services' ? 'active' : ''}>Mes Services</button>
        <button onClick={() => setViewMode('Horaires')} className={viewMode === 'Horaires' ? 'active' : ''}>Mes Horaires</button>
      </div>
      {viewMode === 'Services' ? <ClientServiceForm /> : <ClientHours />}
    </div>
  );
}

export default Dashboard;

