import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const onLogout = async() => {
      try {
        localStorage.removeItem('token');
        setMessage('Logout successful');
        setTimeout(() => {
          navigate('/');
          window.location.reload();
        }, 1000)
      } catch (error) {
        setMessage(error.response.data.error);
      }
    };
    onLogout();
  }, [navigate]);

  return (
    <div>
    <h2>Logout</h2>
    {message && <p className='message'>{message}</p>}
    </div>
  );
}

export default Logout;