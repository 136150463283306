import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import './Schedule.css';
import 'moment/locale/fr';

moment.locale('fr');

const localizer = momentLocalizer(moment);

const CustomToolbar = ({ onNavigate, onView, label, view }) => {
  const navigate = action => {
    onNavigate(action);
  };

  const switchView = newView => {
    onView(newView);
  };

  return (
    <div className="toolbar-container">
      <button className="toolbar-button" onClick={() => navigate('PREV')}>Précedent</button>
      <span className="toolbar-label">{label}</span>
      <button className="toolbar-button" onClick={() => navigate('NEXT')}>Suivant</button>
      <div className="view-buttons">
        <button className={`view-button ${view === 'month' ? 'active' : ''}`} onClick={() => switchView('month')}>Mois</button>
        <button className={`view-button ${view === 'week' ? 'active' : ''}`} onClick={() => switchView('week')}>Semaine</button>
        <button className={`view-button ${view === 'day' ? 'active' : ''}`} onClick={() => switchView('day')}>Jour</button>
      </div>
    </div>
  );
};

function ClientDashboard() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const businessHoursStart = new Date();
  businessHoursStart.setHours(8, 0, 0);

  const businessHoursEnd = new Date();
  businessHoursEnd.setHours(18, 0, 0);

  useEffect(() => {
    fetchAppointments();
  }, []);

    const fetchAppointments = async () => {
    try {
        const response = await axios.get('/api/client/appointments', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        const appointments = response.data
        .filter(appt => appt.DeletedAt === null)
        .map(appt => ({
            id: appt.ID,
            uuid: appt.uuid,
            title: `${appt.name} - ${appt.email}`,
            start: new Date(appt.calendar_entry_start_time),
            end: new Date(appt.calendar_entry_end_time),
            isBooked: true,
            details: appt,
            basket: appt.Basket
        }));
        setEvents(appointments);
    } catch (error) {
        console.error('Error fetching appointments:', error);
    }
    };


  const handleSelectEvent = event => {
    setSelectedEvent(event);
    console.log(selectedEvent);
  };

  const deleteAppointment = async eventId => {
    try {
      const response = await axios.delete(`/api/client/appointments/${eventId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      if (response.status === 200) {
        fetchAppointments();
      } else {
        console.error('Failed to delete appointment:', response.data.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div className="client-dashboard">
      <div className="dashboard-content">
        <Calendar
          localizer={localizer}
          events={events}
          components={{ toolbar: CustomToolbar }}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, flex: 1 }}
          defaultView='week'
          onSelectEvent={handleSelectEvent}
          eventPropGetter={(event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: 'lightgrey',
              color: 'black',
              borderRadius: '0px',
              border: 'none'
            };
            if (event.isBooked) {
              newStyle.backgroundColor = '#ADBBDA';
            }
            if (isSelected) {
              newStyle.backgroundColor = '#7091E6';
            }
            if (event.urgent) {
              newStyle.backgroundColor = '#ff6868';
            }
            return { style: newStyle };
          }}
          messages={{
            next: 'Suivant',
            previous: 'Précédent',
            today: "Aujourd'hui",
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
            agenda: 'Agenda',
            date: 'Date',
            time: 'Heure',
            event: 'Événement',
            noEventsInRange: "Aucun événement à afficher"
          }}
          min={businessHoursStart}
          max={businessHoursEnd}
        />
        {selectedEvent && (
          <div className="event-details">
            <button className="close-button" onClick={() => setSelectedEvent(null)} aria-label="Close">&times;</button>
            <h3>Informations</h3>
            <p>Nom: {selectedEvent.details.name}</p>
            <p>Email: {selectedEvent.details.email}</p>
            <p>Tél: {selectedEvent.details.phone_number}</p>
            <p>Adresse: {selectedEvent.details.address}</p>
            <p>Créneau: {moment(selectedEvent.details.time_slot).format('LLLL')}</p>
            <p>Commentaire: {selectedEvent.details.comment}</p>
            <div>
              <h4>Panier</h4>
              {Array.isArray(selectedEvent.basket) && selectedEvent.basket.length > 0 ? (
                selectedEvent.basket.map(item => (
                  <p key={item.uuid}>{`${item.name} - ${item.price} - Quantité: ${item.quantity}`}</p>
                ))
              ) : (
                <p>Panier vide</p>
              )}
            </div>
            <div className="modify-buttons">
              <button className="button delete-button" onClick={() => deleteAppointment(selectedEvent.uuid)}>Supprimer</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientDashboard;
