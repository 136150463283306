import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ServiceSelection.css';
import { useBooking } from './BookingProvider';
import { SiGoogleearthengine } from "react-icons/si";
import { useScroll } from '../handlers/ScrollManager';
import { MdDashboardCustomize, MdAccessTime } from "react-icons/md";
import { GiMechanicGarage, GiMoneyStack } from "react-icons/gi";
import { TiThSmall } from "react-icons/ti";

function ServiceSelection() {
  const { updateBookingDetails, incrementStep } = useBooking();
  const [services, setServices] = useState({});
  const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [basket, setBasket] = useState([]);
  const [showBasket, setShowBasket] = useState(false);
  const { serviceRef } = useScroll();
  const specificServiceRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [lastAddedService, setLastAddedService] = useState('');


  const getClientNameFromURL = () => {
    const pathname = window.location.pathname;
    const clientName = pathname.split('/')[1];
    return clientName;
  };

  const clientName = getClientNameFromURL();

  useEffect(() => {
    axios.get('/api/services', { params: { client: clientName } })
      .then(response => {
        const data = response.data;
        if (!data || data.length === 0) {
          setAlertMessage('Ce service n\'existe pas');
          setServices({});
        } else {
          const formattedServices = data.reduce((acc, item) => {
            const category = item.category.toLowerCase();
            acc[category] = acc[category] || [];
            acc[category].push({
              name: item.name,
              time: `${item.duration}`,
              price: `${item.price} ${item.currency}`,
              uuid: `${item.uuid}`
            });
            return acc;
          }, {});
          setServices(formattedServices);
          setAlertMessage('');
        }
      })
      .catch(error => {
        console.error('Error fetching services:', error);
        setAlertMessage('Ce service n\'existe pas');
      });
  }, [clientName]);

  const handleServiceCategorySelect = (category) => {
    setSelectedServiceCategory(category === 'all' ? '' : category);
  };

  const displayServices = selectedServiceCategory ?
    services[selectedServiceCategory] :
    [].concat(...Object.values(services));

  const calculateTotalDuration = (basket) => {
    return basket.reduce((total, service) => total + parseFloat(service.time) * service.quantity, 0);
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service.name);
    setBasket(prev => {
      const existingItem = prev.find(item => item.uuid === service.uuid);
      const updatedBasket = existingItem
        ? prev.map(item =>
            item.uuid === service.uuid ? { ...item, quantity: item.quantity + 1 } : item
          )
        : [...prev, { ...service, quantity: 1 }];
      
      updateBookingDetails({
        client_name: clientName,
        duration: calculateTotalDuration(updatedBasket),
        basket: updatedBasket
      });

      return updatedBasket;
    });
    setLastAddedService(service.name);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLastAddedService('');
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [lastAddedService]);

  const handleDeleteFromBasket = (uuid) => {
    setBasket(prev => {
      const existingItem = prev.find(item => item.uuid === uuid);
      const updatedBasket = existingItem.quantity > 1
        ? prev.map(item =>
            item.uuid === uuid ? { ...item, quantity: item.quantity - 1 } : item
          )
        : prev.filter(item => item.uuid !== uuid);
      
      updateBookingDetails({
        client_name: clientName,
        duration: calculateTotalDuration(updatedBasket),
        basket: updatedBasket
      });

      return updatedBasket;
    });
  };
  const getTotalCost = () => {
    return basket.reduce((acc, item) => acc + parseFloat(item.price.split(' ')[0]) * item.quantity, 0);
  };

  if (!Object.keys(services).length || alertMessage) {
    return (
      <div className="service-selection">
        <div className="alert">{alertMessage || 'Aucune information disponible'}</div>
      </div>
    );
  }
  return (
    <div ref={serviceRef} className="service-selection">
      <h2>Sélectionnez les services</h2>
      <div className="layout">
        <div className="service-category-buttons">
          <button
            className={`service-button ${selectedServiceCategory === '' ? 'selected' : ''}`}
            onClick={() => handleServiceCategorySelect('all')}>
            <TiThSmall />
            Toutes les catégories
          </button>
          {Object.keys(services).map(category => (
            <button
              key={category}
              className={`service-button ${selectedServiceCategory === category ? 'selected' : ''}`}
              onClick={() => handleServiceCategorySelect(category)}>
              {category === 'optimisation moteur' ? <SiGoogleearthengine /> : null}
              {category === 'personnalisation' ? <MdDashboardCustomize /> : null}
              {category === 'mecanique' ? <GiMechanicGarage /> : null}
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
        <div ref={specificServiceRef} className="specific-service-buttons">
          {displayServices.map(service => (
            <button key={service.name}
              onClick={() => handleServiceSelect(service)}
              className={`service-button ${selectedService === service.name ? 'selected' : ''}`}>
              <div className="service-detail">
                <span className="service-name">{service.name}</span>
                <div className="time-price">
                  <MdAccessTime />
                  <span>{service.time} min</span>
                  <GiMoneyStack />
                  <span className="price">{service.price}</span>
                </div>
                {lastAddedService === service.name && <span className="added-icon">ajouté &#10004;</span>}
              </div>
            </button>
          ))}
        </div>
      </div>
      <button className="panier-button" onClick={() => setShowBasket(!showBasket)}>
        Mon Panier {getTotalCost()} EUR
      </button>
      <button
        className="schedule-button"
        onClick={() => {
          if (!selectedService) {
            setAlertMessage("Choisissez un service d'abord");
          } else {
            incrementStep();
            setAlertMessage('');
          }
        }}>
        Je choisis un créneau
      </button>
      {showBasket && (
        <div className="basket-container">
          <h3>Panier</h3>
          {basket.map((item, index) => (
            <div key={index} className="basket-item">
              <span>{item.name} - {item.price} - {item.time} min - Quantité: {item.quantity}</span>
              <button onClick={() => handleDeleteFromBasket(item.uuid)}>Retirer</button>
            </div>
          ))}
          <div className="total-cost">
            <strong>Total: </strong>{getTotalCost()} EUR
          </div>
        </div>
      )}
      {alertMessage && (
        <div className="alert">
          {alertMessage}
        </div>
      )}
    </div>
  );
}

export default ServiceSelection;
