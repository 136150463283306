import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './TimeSlot.css';
import { format, isBefore, isAfter, startOfDay } from 'date-fns';
import axios from 'axios';
import { useBooking } from '../service/BookingProvider';
import { useScroll } from '../handlers/ScrollManager';

const TimeSlotCalendar = () => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [availableDays, setAvailableDays] = useState([]);
  const { bookingDetails, incrementStep } = useBooking();
  const { updateBookingDetails } = useBooking();
  const { currentStep } = useBooking();
  const { scrollToRef, timeslotRef } = useScroll();

  // Extract client name from URL
  const getClientNameFromURL = () => {
    const pathname = window.location.pathname; // /carwash
    const clientName = pathname.split('/')[1]; // splits the pathname and gets 'carwash'
    return clientName;
  };

  const clientName = getClientNameFromURL();

  const fetchAvailableTimeSlots = useCallback(async (year, month) => {
    const formattedMonth = `${year}-${month.toString().padStart(2, '0')}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const response = await axios.get(`/api/appointments/slots`, {
        params: {
          month: formattedMonth,
          duration: bookingDetails.duration,
          timezone,
          client: clientName,
        }
      });

      const timeSlots = response.data.available_time_slots;
      setAvailableDays(timeSlots);
    } catch (error) {
      console.error('Failed to fetch available time slots', error);
      setAvailableDays({});
    }
  }, [bookingDetails.duration, clientName]);

  useEffect(() => {
    if (selectedDay) {
      const year = selectedDay.getFullYear();
      const month = selectedDay.getMonth() + 1; // getMonth is zero-indexed
      fetchAvailableTimeSlots(year, month);
    }
  }, [selectedDay, fetchAvailableTimeSlots]);

  useEffect(() => {
    if (currentStep === 1 && timeslotRef.current) {
      scrollToRef(timeslotRef);
    }
  }, [currentStep, timeslotRef, scrollToRef]);

const renderTimeSlots = () => {
  const dateKey = format(selectedDay, 'yyyy-MM-dd');
  const slots = availableDays[dateKey] || [];
  const startOfTomorrow = startOfDay(new Date());
  startOfTomorrow.setDate(startOfTomorrow.getDate() + 1);

  return slots.map((slot, index) => {
    const slotDateTime = new Date(`${dateKey}T${slot}`);
    if (isBefore(slotDateTime, startOfTomorrow)) {
      return null;
    }
    return (
      <div key={slot} className={`timeslot ${selectedTimeSlot === slot ? 'selected' : ''}`}
           onClick={() => handleTimeSlotClick(slot)}>
        {slot}
        <div className="dot"></div>
      </div>
    );
  }).filter(Boolean);
};

  const handleDayClick = (value) => {
    setSelectedDay(value);
    updateBookingDetails({ day: value });
    setSelectedTimeSlot('');
  };

  const handleTimeSlotClick = (slot) => {
    incrementStep();
    setSelectedTimeSlot(slot);
    updateBookingDetails({ start_time: slot, client_name: clientName });
  };

  return (
    <div ref={timeslotRef} className="calendar-container">
      <div className='timeslot-selection'>
        <h2>Sélectionnez un jour</h2>
        <Calendar
          onChange={handleDayClick}
          value={selectedDay}
          locale="fr-FR"
          tileClassName={({ date, view }) => {
            if (view === 'month' && availableDays[format(date, 'yyyy-MM-dd')]) {
              return 'highlight';
            }
          }}
          tileContent={({ date, view }) => {
            const today = startOfDay(new Date());
            const isFutureDate = isAfter(date, today);
            if (view === 'month' && availableDays[format(date, 'yyyy-MM-dd')] && isFutureDate) {
              return <div className="dot"></div>;
            }
          }}
          tileDisabled={({ date, view }) => 
            view === 'month' && !isAfter(startOfDay(date), startOfDay(new Date()))
          }
        />

      </div>
      {selectedDay && (
        <>
          <h2>Sélectionnez un créneau horaire</h2>
          <div className="timeslot-container">
            {renderTimeSlots()}
          </div>
        </>
      )}
      <div className="button-container">
        <button className="button" onClick={() => setSelectedDay(null)}>Supprimer la sélection</button>
      </div>
    </div>
  );
};

export default TimeSlotCalendar;
