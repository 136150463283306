import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ClientHours.css';

function ClientHours() {
  useEffect(() => {
    const fetchHoursData = async () => {
      try {
        const response = await axios.get('/api/client/hours');
        const hourData = response.data.data[0];
        if (hourData) {
          setState({
            uuid: hourData.UserUUID,
            formState: {
              openingTime: hourData.opening_time ?? '08:00',
              closingTime: hourData.closing_time ?? '19:00',
              workDays: {
                lundi: hourData.work_monday ?? false,
                mardi: hourData.work_tuesday ?? false,
                mercredi: hourData.work_wednesday ?? false,
                jeudi: hourData.work_thursday ?? false,
                vendredi: hourData.work_friday ?? false,
                samedi: hourData.work_saturday ?? false,
                dimanche: hourData.work_sunday ?? false,
              },
              breakStart: hourData.break_start ?? '12:30',
              breakEnd: hourData.break_end ?? '13:30',
            }
          });
        }
      } catch (error) {
        console.error('Failed to fetch existing hours:', error);
      }
    };

    fetchHoursData();
  }, []);
    const [Message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [state, setState] = useState({
      uuid: null,
      formState: {
        openingTime: '08:00',
        closingTime: '19:00',
        workDays: {
          lundi: true,
          mardi: true,
          mercredi: true,
          jeudi: true,
          vendredi: true,
          samedi: true,
          dimanche: false
        },
        breakStart: '12:30',
        breakEnd: '13:30',
      }
    });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setState(prevState => ({
      ...prevState,
      formState: {
        ...prevState.formState,
        [name]: type === "checkbox" ? checked : value,
        workDays: type === "checkbox" ? {
          ...prevState.formState.workDays,
          [name]: checked
        } : {...prevState.formState.workDays}
      }
    }));
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      const endpoint = state.uuid ? `/api/client/hours/${state.uuid}` : '/api/client/hours';
      const method = state.uuid ? 'put' : 'post';

      // Mapping the French days to the expected server keys based on the GORM struct
      const daysMapping = {
          lundi: 'work_monday',
          mardi: 'work_tuesday',
          mercredi: 'work_wednesday',
          jeudi: 'work_thursday',
          vendredi: 'work_friday',
          samedi: 'work_saturday',
          dimanche: 'work_sunday'
      };

      const workDays = Object.keys(state.formState.workDays).reduce((acc, day) => {
          acc[daysMapping[day]] = state.formState.workDays[day];
          return acc;
      }, {});

      // Ensure to match JSON property names with GORM tags in the ClientInput struct
      const dataToSend = {
          uuid: state.uuid,
          opening_time: state.formState.openingTime,
          closing_time: state.formState.closingTime,
          break_start: state.formState.breakStart,
          break_end: state.formState.breakEnd,
          ...workDays
      };

      axios[method](endpoint, dataToSend)
          .then(response => {
              console.log('Success:', response.data);
              setMessage('Vos modifications ont été enregistrées');
              setState(prevState => ({
                  ...prevState,
                  uuid: response.data.data.UserUUID
              }));
          })
          .catch(error => {
              console.error('Error submitting the data:', error);
              setErrorMessage("Erreur lors de l'enregistrement, veuillez contacter le support");
          });
  };

  return (
    <div className="client-hours-form">
      <h2>Définissez vos heures de travail</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Heure d'ouverture:
          <input
            type="time"
            name="openingTime"
            value={state.formState.openingTime}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Heure de fermeture:
          <input
            type="time"
            name="closingTime"
            value={state.formState.closingTime}
            onChange={handleInputChange}
          />
        </label>

        <fieldset>
          <legend>Jours travaillés:</legend>
          {Object.keys(state.formState.workDays).map((day) => (
            <label key={day}>
              {day.charAt(0).toUpperCase() + day.slice(1)}:
              <input
                type="checkbox"
                name={day}
                checked={state.formState.workDays[day]}
                onChange={handleInputChange}
              />
            </label>
          ))}
        </fieldset>

        <label>
          Temps de pause (de):
          <input
            type="time"
            name="breakStart"
            value={state.formState.breakStart}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Temps de pause (à):
          <input
            type="time"
            name="breakEnd"
            value={state.formState.breakEnd}
            onChange={handleInputChange}
          />
        </label>

        <button type="submit">Soumettre</button>
      {Message && <div className="success-message">{Message}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>
    </div>
  );
}

export default ClientHours;
