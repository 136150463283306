import React from 'react';
import { Link } from 'react-router-dom';
import './Homepage.css';
import { useUser } from './UserContext';

const HomePage = () => {
  const { clientID } = useUser();

  return (
    <div className="home-container">
      <h2>Vos rendez-vous en 3 étapes</h2>
      <div className="steps-container">
        <div className="step">
          <div className="circle">1</div>
          <p>J'indique les services que je propose, mes horaires d'ouverture</p>
          <Link to="/setup" className="button">Setup</Link>
        </div>
        <div className="step">
          <div className="circle">2</div>
          <p>Je consulte ma page de booking, je la partage à mes clients</p>
          <Link to={`/${clientID}`} className="button">Ma page</Link>
        </div>
        <div className="step">
          <div className="circle">3</div>
          <p>Je consulte mon dashboard ainsi que mes analytics.</p>
          <Link to="/dashboard" className="button">Dashboard</Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
