import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { BookingProvider } from './components/page/service/BookingProvider';
import { ScrollProvider } from './components/page/handlers/ScrollManager';
import { UserProvider } from './components/users/UserContext';

// Intercept requests to automatically add the refreshed token

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BookingProvider>
      <ScrollProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </ScrollProvider>
    </BookingProvider>
  </React.StrictMode>,
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();