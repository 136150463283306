import React, { useEffect } from 'react';
import './UserInfoForm.css';
import { useBooking } from './BookingProvider';
import { useScroll } from '../handlers/ScrollManager';
import { useNavigate, useLocation } from 'react-router-dom';

function UserInfoForm() {
  const { bookingDetails } = useBooking();
  const { currentStep } = useBooking();
  const { scrollToRef, userInfoRef } = useScroll();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const date = new Date(bookingDetails.day);
    const [hours, minutes] = bookingDetails.start_time.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);

    const userInfo = {
      name: e.target.name.value.trim(),
      phone_number: e.target.phone.value.trim(),
      email: e.target.email.value.trim(),
      address: e.target.address.value.trim(),
      start_time: date.toISOString(),
      duration: parseInt(bookingDetails.duration, 10),
      comment: e.target.comment.value.trim(),
      client: bookingDetails.client_name,
      basket: bookingDetails.basket, 
    };

    try {
      const response = await fetch('/api/appointments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userInfo)
      });

      const data = await response.json();
      if (response.ok) {
        // Construct a relative path for navigation
        navigate(`${location.pathname}/confirmation`, { state: { details: userInfo } });
      } else {
        navigate(`${location.pathname}/confirmation`, { state: { error: data.message || "Une erreur est survenue. Nous nous excusons pour la gêne occasionée. Merci de nous contacter pour la prise de rendez-vous" } });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      navigate(`${location.pathname}/confirmation`, { state: { error: "Une erreur est survenue. Nous nous excusons pour la gêne occasionée. Merci de nous contacter pour la prise de rendez-vous" } });
    }
  };

  useEffect(() => {
    if (currentStep === 2 && userInfoRef.current) {
      scrollToRef(userInfoRef);
    }
  }, [currentStep, userInfoRef, scrollToRef]);

  return (
    <form onSubmit={handleSubmit} ref={userInfoRef} className="user-info-form">
      <div className="message-header">On vous appellera une fois sur place !</div>
      <input type="text" name="name" placeholder="Nom *" required defaultValue={bookingDetails.userInfo.name} />
      <input type="tel" name="phone" placeholder="Téléphone *" required defaultValue={bookingDetails.userInfo.phone} />
      <input type="text" name="address" placeholder="Adresse (en Ile de France) *" required defaultValue={bookingDetails.userInfo.address} />
      <input type="email" name="email" placeholder="Email *" required defaultValue={bookingDetails.userInfo.email} />
      <input type="text" name="comment" placeholder="Une précision ? (facultatif)" />
      <button type="submit">Confirmer le rendez-vous</button>
    </form>
  );
}

export default UserInfoForm;
