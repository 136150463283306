import React, { createContext, useContext, useState } from 'react';

const BookingContext = createContext();

export const useBooking = () => useContext(BookingContext);

export const BookingProvider = ({ children }) => {
  const [bookingDetails, setBookingDetails] = useState({
    date: '',
    userInfo: {}
  });
  const [currentStep, setCurrentStep] = useState(0);

  const updateBookingDetails = (details) => {
    setBookingDetails(prev => ({ ...prev, ...details }));
    console.log("current booking details", bookingDetails)
  };
  const incrementStep = () => {
    setCurrentStep(prev => {
      return prev + 1;
    });
  };

  const decrementStep = () => {
    setCurrentStep(prev => prev > 0 ? prev - 1 : 0);
  };
  return (
    <BookingContext.Provider value={{ bookingDetails, updateBookingDetails, currentStep, incrementStep, decrementStep }}>
      {children}
    </BookingContext.Provider>
  );
};
