import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h1>Un renseignment ? <br></br>Contactez-nous !</h1>
      <div>
        <div className="label">Téléphone:</div>
        <div className="detail">+33 6 25 88 72 84</div>
      </div>
      <div>
        <div className="label">Whatsapp:</div>
        <div className="detail">+33 6 25 88 72 84</div>
      </div>
      <div>
        <div className="label">Adresse mail:</div>
        <div className="detail">contact@carutile.com</div>
      </div>
      <div>
        <div className="label">Instagram:</div>
        <div className="detail">@Carwash.Carutile</div>
      </div>
    </div>
  );
}

export default About;
