import React, { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import './ClientService.css';
import FAQ from '../page/FAQ';
import About from '../page/About';
import Footer from '../page/footer/Footer';
import TermsOfUse from '../page/TermsOfUse';
import PrivacyPolicy from '../page/PrivacyPolicy';
import TimeSlotCalendar from '../page/timeslot/Timeslot';
import UserInfoForm from '../page/service/UserInfoForm';
import ServiceSelection from '../page/service/ServiceSelection';
import Confirmation from '../page/service/Confirmation';
// import Banner from '../page/service/Banner';
import { useBooking } from '../page/service/BookingProvider';
import RouteHandler from '../page/handlers/RouteHandler';
import { useScroll } from '../page/handlers/ScrollManager';

function ClientService() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { currentStep } = useBooking();
  const { scrollToRef, vehicleRef } = useScroll();
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen); // Toggle the visibility of the nav links
  };

  return (
      <div className="client-service">
            <nav className="client-nav">
              <button onClick={toggleNav} className="menu-toggle">☰</button>
              <div className={`client-nav-links ${isNavOpen ? 'active' : ''}`}>
                <div className="nav-group">
                  <Link to="" className='bold-italic nav-item' onClick={() => setIsNavOpen(false)}>ACCUEIL</Link>
                  <button className='bold-italic nav-item rdv' onClick={() => {
                      setIsNavOpen(false);
                      scrollToRef(vehicleRef);
                  }}>PRENDRE RDV</button>
                  <button className='bold-italic nav-item' onClick={() => {
                      setIsNavOpen(false);
                      scrollToRef(vehicleRef);
                  }}>PRESTATIONS & TARIFS</button>
                  <Link to="galerie" className='bold-italic nav-item' onClick={() => setIsNavOpen(false)}>GALERIE</Link>
                </div>
                <div className="nav-group">
                  <Link to="faq" className='bold-italic nav-item' onClick={() => setIsNavOpen(false)}>FAQ</Link>
                  <Link to="about" className='bold-italic nav-item' onClick={() => setIsNavOpen(false)}>CONTACT</Link>
                </div>
              </div>
            </nav>
              <div className="service-content"> {/* Main content and routes */}
                <Routes>
                  <Route index element={
                  <RouteHandler>
                    {/* <Banner /> */}
                    {currentStep >= 0 && <ServiceSelection />}
                    {currentStep >= 1 && <TimeSlotCalendar />}
                    {currentStep >= 2 && <UserInfoForm />}
                  </RouteHandler>
                } />
                  <Route path="confirmation" element={<Confirmation />} />
                  <Route path="faq" element={<FAQ />} />
                  <Route path="about" element={<About />} />
                  <Route path="terms-of-use" element={<TermsOfUse />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
              </div>
            <Footer />
      </div>
  );
}

export default ClientService;
