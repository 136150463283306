import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [clientID, setClientID] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch(`/api/user/info`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUsername(data.username);
            setClientID(data.clientID);
          }
        } catch (error) {
          console.error('Failed to fetch user info:', error);
        }
      }
    };
    
    fetchUserInfo();
  }, []);

  return (
    <UserContext.Provider value={{ username, setUsername, clientID }}>
      {children}
    </UserContext.Provider>
  );
}
