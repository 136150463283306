import React from 'react';
import './PrivacyPolicy.css'; // Ensure you import the CSS file for styling

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <h1>Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>
        Carutile is committed to protecting your privacy. This policy outlines our practices concerning the collection, use, and disclosure of your information when you use our app. By accessing the app, you agree to the collection and use of information in accordance with this policy.
      </p>
      <h2>Information Collection And Use</h2>
      <p>
        We collect several different types of information for various purposes to provide and improve our service to you. This may include, but is not limited to, personally identifiable information (such as your email address) and usage data (such as information on how the app is accessed and used).
      </p>
      <h2>Use of Data</h2>
      <p>
        Carutile uses the collected data for various purposes, including to provide and maintain the app, notify you about changes to our service, allow you to participate in interactive features of our app when you choose to do so, provide customer care and support, and monitor the usage of the app.
      </p>
      <h2>Data Security</h2>
      <p>
        The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is completely secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
