import React, { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext();

export function useScroll() {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error('useScroll must be used within a ScrollProvider');
    }
    return context;
}

export const ScrollProvider = ({ children }) => {
  const vehicleRef = useRef(null);
  const serviceRef = useRef(null);
  const timeslotRef = useRef(null);
  const userInfoRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <ScrollContext.Provider value={{ vehicleRef, serviceRef, timeslotRef, userInfoRef, scrollToRef }}>
      {children}
    </ScrollContext.Provider>
  );
};
