import React, { useState } from 'react';
import Schedule from './Schedule';
import Analytics from './Analytics';
import './Dashboard.css';

function Dashboard() {
  const [viewMode, setViewMode] = useState('Schedule'); // Default to 'Schedule'

  return (
    <div className="dashboard-container">
      <div className="view-switch">
        <button onClick={() => setViewMode('Schedule')} className={viewMode === 'Schedule' ? 'active' : ''}>Calendrier</button>
        <button onClick={() => setViewMode('Analytics')} className={viewMode === 'Analytics' ? 'active' : ''}>Analytics</button>
      </div>
      {viewMode === 'Schedule' ? <Schedule /> : <Analytics />}
    </div>
  );
}

export default Dashboard;
